.lds-hourglass {
margin: auto;
  width: 80px;
  height: 80px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #dedede;
  border-color: #dedede transparent #dedede transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

iframe{
  z-index: 40;
  position: relative;
}

.popup-inner{
max-height: 90vh;
}
@media screen and (min-width:1024px){
.popup-inner{
  max-height: 700px;
}


}
@media screen and (max-height:700px){
.popup-inner{
height: 100vh;
}
}
.poweredByPaid {
  display: none !important;
}
blockquote{
  font-size: 0.8rem;
  font-style: italic;
}

.gradient:after{
  content: "";
position: absolute;
left:0;
right:0;
top:0;
bottom:0;
width: 100%;
height: 100%;
background: linear-gradient(13deg, rgba(0,0,0,0.8085609243697479) 21%, rgba(0,0,0,0) 52%);
}
@media screen and (max-width:767px){
.slider-image{
  max-height: 55vh;
  min-height: 40vh;
  width: 140%;
  transform: translateX(-20%);
  height:100%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  overflow: hidden;

  div{
    width: 100%;
    height:100%;
  }
}

}

.gallery .swiper-slide{
  width: auto;
}
.swiper-pagination{
  background-color: transparent !important;
      @media screen and (max-width:767px){
        margin: 0px !important;
}
}
.swiper-pagination-bullet{
  position: relative !important;
  width: auto!important;
  margin-bottom: 35px;
  height:auto!important;
  display: block!important;
  background-color: transparent !important;
  text-align: left; margin-left: 20px;
  opacity: 0.8 !important;
  font-weight: 400;
  @media screen and (max-width:1280px){
        font-size: 0.7em;
  }


    &:focus{
      outline:none;
    }

  &-active{
    font-size: 1.4em;
        @media screen and (max-width:1280px){
              font-size: 1.1em;
        }
        @media screen and (max-width:1024px){
              font-size: 0.9em;
        }
  }


    @media screen and (max-width:767px){
      width: 100% !important;
        opacity: 1 !important;
      height: 68px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      padding: 0 4px;
        background-color: inherit !important;
        border-radius: 0px !important;
        margin:0px !important;
        text-align: center !important;
      color: #809DAF;
      border-color: rgba(255, 255, 255, 0.3) !important;
          border-bottom: 4px solid ;

      &-active{
            color: #fff !important;
      }
      .dash {
        display:none;
      }

      .inner-span{
        z-index: 3;
        position: relative;
      }
      &:before{
        content: "";
        position: absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        width:100%;
        height:100%;

        background-color: #F8F8F8;
        pointer-events: none;
        z-index: 2;
      }
      &-active:after{
        background-color: inherit;
      }
    }

  &-active{
    font-weight: 800;
        @media screen and (min-width:767px){
    margin-left: 10px !important;

  }
  }
}

.swiper-pagination-bullet-active:before{
  background: transparent;
}
      @media screen and (min-width:767px){
.title-box{
  bottom:180px;
background-image: none !important;
}
}
      @media screen and (max-width:767px){
.title-box{
  bottom:180px;
  z-index: 50
}

.title-box:before{
  content: "";
  width: 100%;
  height: 40vh;
  background-image: inherit;
  opacity: 0.4;
  position:absolute;
  left:0;
  right:0;
  bottom:10vh;
}
}

@media screen and (min-width:767px){
  .module-bg:nth-child(4n-1){
    background-color: #f7fafc !important;
  }

  .module-bg:nth-child(4n-2){
    background-color: #f7fafc !important;
  }
}
@media screen and (max-width:767px){
.module-bg:nth-child(even){
  background-color: #f7fafc !important;
}
}



.feature-image:nth-child(1){
  width: 17vw;
  height: 17vw;
  position: absolute;
  left:-4vw;
  top:0;
  z-index: 2;
  max-width:300px;
  max-height:300px;
  @media screen and (max-width: 1100px){
    display: none;
  }
}

.feature-image:nth-child(2){

    position: absolute;
    left:0;
    right:0;
    top:0;
  width: 30vw;
  height: 30vw;
  max-width:560px;
  max-height:560px;
    z-index: 0;
    margin: auto;
  bottom:0;

    @media screen and (max-width: 1100px){
      width: 40vw;
      height: 40vw;
    }
  @media screen and (max-width: 767px){
    display: none;
  }


}

  @media screen and (min-width: 767px){
.text-contain{
  background: transparent !important
}

}
  @media screen and (max-width: 767px){
.bg-mob{
    height: 80vh;
}

.feature-image-mob{
  position: absolute;
  left:0;
  right:0;
  top:-0%;
  width: 135%;
      transform: translateX(-15%);
      height: 70%;
  z-index: 1;
}
}

.text-content{
  margin-top:20vh;
    @media screen and (max-width: 767px){
      margin-top: 0px !important;
    }
}

.contact-section{
  padding-top:20vh !important;
  height:70vh;
  @media screen and (max-width: 767px){

  height:auto;

justify-content: center;

  padding-top: 0px !important;
  .bg-mob{
  height:20vh !important;
}
}
}
.welcome-section{

  @media screen and (max-width: 767px){
background: transparent !important;
  height:auto;

justify-content: center;

  }
}

@media screen and (max-width: 767px){

.banner-vid{
 height: auto;
}
}
.feature-image:nth-child(3){
  width: 15vw;
  height: 15vw;
  position: absolute;
  bottom:0;
  right:4vw;
  z-index: 2;
  max-width:200px;
  max-height:200px;
  @media screen and (max-width: 1100px){
    display: none;
  }
}
.media-wrapper{
  overflow: hidden;
  -webkit-backface-visibility: hidden;
-moz-backface-visibility: hidden;
-webkit-transform: translate3d(0, 0, 0);
-moz-transform: translate3d(0, 0, 0)
}
.media-wrapper:after{
  content: "";
  z-index: 10;
  position: absolute;
  top:0;
  bottom:0;
  right:0;
  z-index: 1;
  width: 60%;
  height:100%;

  @media screen and (max-width: 1620px) and (min-width: 768px){
   background: linear-gradient(-90deg, rgba(0,0, 0, 0.8) 0%, rgba(0,0, 0,0) 100%);
 }
}

.plan:nth-child(even) div{
  border-style:solid;
  border-width: 2px;
}
