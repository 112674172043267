/// General layout
.container {
  max-width: 1400px;
  width: 90%;

  &-small{
    max-width: 900px;
    width: 90%;
      margin: 0 auto;
  }

  &-wide{
    max-width: 1800px;
    width: 90%;
      margin: 0 auto;
  }
}
